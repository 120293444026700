.home{
    min-height: 115vh;
    background-image: url(../assets/images/destiny2-traveler-2.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .gc-main-container{
    width: 100%!important;
  }  
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .gc-main-container{
    width: 100%!important;
  }  
}

@media only screen and (min-width: 1300px) {
  .gc-main-container{
    width: 80%!important;
  }  
}

@media only screen and (min-width: 1600px) {
  .gc-main-container{
    width: 75%!important;
  }  
}

@media only screen and (min-width: 1700px) {
  .gc-main-container{
    width: 60%!important;
  }  
}
