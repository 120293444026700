body { 
  min-height: 115vh;
  background-image: url(./assets/images/destiny2-traveler-2.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.App {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

h1 { 
  color: #b48608; 
  font-family: 'Droid serif', serif; 
  font-size: 36px; 
  font-weight: 400; 
  font-style: italic; 
  line-height: 44px; 
  margin: 0 0 12px; 
  text-align: center; 
}


p { color: #eee; font-family: 'Droid Sans', sans-serif; font-size: 15px; font-weight: 400; line-height: 24px; margin: 0 0 14px; }


a { color: #ffff; text-decoration: none; }

a:hover { color: #b48608; text-decoration: blink; }


.date { border-top: 1px solid #fff; position: relative; top: 20px; margin-bottom: 30px; }


.date span { color: #000; text-decoration: none; font-style: italic; font-size: 13px; text-align: center; padding: 2px 5px; background: #fff; width: 120px; margin: 0 auto; display: block; position: relative; top: -10px; font-family: "Droid serif", serif; }


a.btn { font-family: 'Droid serif', serif; background: #b48608; padding: 4px 7px; color: #000; text-decoration: none; margin: 0 auto; width: 100%; width: 120px; margin: 0 auto; display: block; text-align: center; font-style: italic; }


a.btn:hover { color: #000; background: #fff; }


.left { float: left; margin: 0 5% 30px 0; width: 30%; }


.right { float: right; margin: 0 0 30px 0; width: 30%; }
.card-nav{
  color: #b48608;
  font-family: 'Droid serif', serif; 
}
.card-header {
  color: #b48608;
  font-family: 'Droid serif', serif;

}
.card-body{
  color: #000;
  font-family: 'Droid serif', serif; 
  background-color: #F5F5DC;
  
}

.nav-link{
  color: #b48608;
  font-family: 'Droid serif', serif;
  text-decoration: none; 
}